/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-option {
    font-size: inherit;
    line-height: 3em;
    height: 3em;
  }

  .mdc-list-item__primary-text {
    display: inline-block;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap !important;
    // font-family: "Barlow,sans-serif" !important;
    font-size: 14px !important;
}

.mat-drawer[style*="visibility: hidden"] {
    display: block !important; 
}
  
